@charset "utf-8";

@media (min-width: 1001px) {
	body {
		font-size: 14px;
	}

	p {
		margin-top: 10px;
	}

	select {
		border-radius: 5px;
		border: 1px solid #bebebe;
	}

	ol, ul {
		padding: 10px;
		margin: 10px;
	}

	.blog-post-content {
		color: #1a1a1a;
		font-size: 14px;
		font-family: 'Montserrat';
		width: 100%;
		margin-top: 10px;
	}

	.blog-post-text {
		color: #0c0c0c;
		font-size: 12px;
		font-family: 'Roboto Slab';
		margin-top: 10px;
	}

	.blog-post-sig {
		margin-top: 20px;
		color: #777676;
		font-size: 10px;
		font-family: 'Montserrat';
	}

	.content-container {
		width: 790px;
		float: right;
		background: #ffffff;
		color: #0c0c0c;
		font-size: 12px;
		font-family: 'Roboto Slab';
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.content-sub-container {
		color: #0c0c0c;
	}

	.content-title {
		color: #1a1a1a;
		font-size: 18px;
		font-family: 'Montserrat';
		margin-bottom: 40px;
	}

	.content-container-padding {
		padding: 40px;
	}

	.screenshot-desktop {
		max-width: 200px;
	}

	.edu-container, .blog-post-container {
		margin-top: 40px;
	}

	.edu-sub-container, .blog-post-sub-container {
		display: inline-block;
		width: 100%;
	}

	.edu-img-container {
		float: left;
	}

	.edu-img {
		width: 100px;
		display: block;
		border-radius: 5px;
	}

	.edu-content {
		color: #1a1a1a;
		font-size: 14px;
		font-family: 'Montserrat';
		float: left;
		width: 550px;
	}

	.edu-content-padding {
		padding-left: 25px;
	}

	.edu-title {
		color: #1a1a1a !important;
		font-size: 18px !important;
		font-family: 'Montserrat' !important;
	}

	.edu-text {
		font-size: 14px !important;
	}

	.edu-sub-title {
		color: #777676 !important;
		font-size: 12px !important;
		font-family: 'Montserrat' !important;
		margin-top: 5px !important;
	}

	.edu-title, .blog-post-title {
		color: #1a1a1a;
		font-size: 14px;
		font-family: 'Montserrat';
	}

	.edu-sub-title, .blog-post-sub-title {
		color: #777676;
		font-size: 10px;
		font-family: 'Montserrat';
		margin-top: 5px;
	}

	.edu-separator {
		border-bottom: 1px solid #bebebe;
		margin-top: 40px;
	}

	.edu-text {
		color: #0c0c0c;
		font-size: 12px;
		font-family: 'Roboto Slab';
		margin-top: 5px;
	}

	.main-contents-container {
		width: 1000px;
		margin: auto;
	}

	.main-contents-sub-container {
		display: inline-block;
		width: 100%;
	}

	.mobile-only {
		display: none;
	}

	.nav-default {
		font-size: 10px !important;
		margin-top: 20px !important;
		color: #bebebe;
		cursor: pointer;
	}

	.nav-default:hover {
		cursor: pointer;
		color: #ffffff;
	}

	.nav-selected {
		font-size: 10px !important;
		margin-top: 20px !important;
		color: #ffffff;
		cursor: pointer;
	}

	.sidebar-container {
		width: 190px;
		float: left;
		margin-top: 40px;
		position: fixed;
	}

	.sidebar-img {
		height: 175px;
		width: 175px;
		border-radius: 50%;
		margin-left: 7px;
	}

	.sidebar-name {
		margin-top: 15px !important;
		font-size: 26px !important;
	}

	.sidebar-sub-name {
		color: #777676 !important;
	}

	.sidebar-social-container {
		width: 100%;
		margin-top: 30px !important;
		font-size: 16px !important;
	}

	.sidebar-nav-container {
		width: 100%;
		margin-top: 30px;
	}

	.social-left {
		margin-right: 8px;
	}

	.social-mid {
		margin-left: 8px !important;
		margin-right: 8px !important;
		width: 20px !important;
	}

	.social-right {
		margin-left: 8px;
	}

	.skills-ul {
		list-style: none;
		margin: 0px;
		padding: 0px;
	}

	.skills-ul li {
		display: inline-block;
		float: left;
		width: 170px;
		margin-top: 15px;
	}

	.web-only {
		display: block;
	}

	.faport {
		width: 100px;
		text-align: center;
	}
}

