.sidebar-container {
    .sidebar-name {
        color: #ffffff;
    }

    h3 {
        display: block;
        font-size: 1.17em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        line-height: 1.2 !important;
    }
}