@charset "utf-8";

* {
	margin: 0px;
	padding: 0px;
}

body {
	font-family: "Montserrat" !important;
	background: #0c0c0c !important;
	color: #ffffff;
}

a {
	color: #9e9e9e !important;
	text-decoration: none !important;
}

a:hover {
	color: #1a1a1a !important;
	text-decoration: none;
}

.social-icon {
	color: #ffffff !important;
	text-decoration: none !important;
}

.social-icon:hover {
	color: #ffffff !important;
	text-decoration: none !important;
}

pre{
	font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
	margin-bottom: 10px;
	overflow: auto;
	width: auto;
	font-size:12px;
	padding: 5px;
	background-color: #eee;
	max-height: 600px; 
	-moz-tab-size: 4;
	-o-tab-size:   4;
	tab-size:      4;
}

#fb_in {
	width: 100%;
	display: inline-block;
	margin-top: 20px;
	margin-bottom: 20px;
}

#fb_out {
	width: 100%;
	display: inline-block;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	border-bottom: 1px solid #dedede;
	padding-top: 20px;
	padding-bottom: 40px;
}

#status {
	margin-top: 5px;
}

.comment-area {
	float: left;
	display: inline-block;
}

.comment-area-img-container {
	float: left;
	display: inline-block;
}

.comment-area-img {
	display: block;
	width: 25px;
}

.comment-area-name {
	float: left;
	display: inline-block;
	font-family: Montserrat;
	margin-left: 5px;
	margin-top: 5px;
	color: #365899;
}

.comment-area-textarea-container {
	width: 100%;
	display: inline-block;
	margin-top: 10px;
}

.comment-area-textarea-container textarea {
	font-family: Roboto Slab;
	font-size: 12px;
	width: 100%;
	border: 1px solid #dedede;
	box-sizing: border-box;
	padding: 8px;
	resize: none;
}

.comment-area-button-container {
	width: 100%;
	margin-top: 10px;
}

.comment-area-button {
	background: #365899;
	color: #fff;
	font-family: Montserrat;
	display: inline-block;
	cursor: pointer;
	width: 100%;
	text-align: center;
}

.comment-area-pad {
	padding: 10px;
}

.comments-header {
	margin-top: 20px;
	background: #dedede;
	width: 100%;
	text-align: center;
}

.comments-header-pad {
	padding: 3px;
}

.comments-header-text {
	font-family: Montserrat;
	font-size: 10px;
	font-weight: bold;
	color: #1a1a1a;
}

.comment-container {
	width: 100%;
	display: inline-block;
	margin-bottom: 20px;
}

.comment-img-container {
	float: left;
	display: inline-block;
}

.comment-img-container img {
	display: block;
	width: 40px;
}

.comment-user-info {
	margin-left: 7px;
	margin-top: 5px;
	float: left; 
	display: inline-block;
	font-family: Montserrat;
}

.comment-user-info h3{
	color: #365899;
}

.comment-user-info-date {
	margin-top: 2px;
	font-size: 9px;
	color: #999;
}

.comment-text {
	width: 100%;
	display: inline-block;
	margin-top: 10px;
	padding-bottom: 20px;
	border-bottom: 1px solid #dedede;
}