@charset "utf-8";

@media (max-width: 1000px) {

	body {
		padding: 10px;
	}

	img {
		width: 100%;
	}

	p {
		margin-top: 10px;
	}

	select {
		border-radius: 5px;
		border: 1px solid #bebebe;
	}

	ol, ul {
		padding: 10px;
		margin: 10px;
	}

	.blog-post-content {
		color: #1a1a1a;
		font-size: 14px;
		font-family: 'Montserrat';
		width: 100%;
		margin-top: 10px;
	}

	.blog-post-title {
		color: #1a1a1a;
		font-size: 14px;
		font-family: 'Montserrat';
	}

	.blog-post-sub-title {
		color: #777676;
		font-size: 10px;
		font-family: 'Montserrat';
	}

	.blog-post-text {
		color: #0c0c0c;
		font-size: 12px;
		font-family: 'Roboto Slab';
		margin-top: 10px;
	}

	.blog-post-sig {
		margin-top: 20px;
		color: #777676;
		font-size: 10px;
		font-family: 'Montserrat';
	}

	.content-container {
		width: 100%;
		background: #ffffff;
		color: #0c0c0c;
		font-size: 12px;
		font-family: 'Roboto Slab';
		margin-top: 65px;
	}

	.content-sub-container {
		color: #0c0c0c;
	}

	.content-container-padding {
		padding: 20px;
	}

	.edu-container, .blog-post-container {
		margin-top: 40px;
	}

	.edu-sub-container, .blog-post-sub-container {
		display: inline-block;
		width: 100%;
	}

	.edu-img-container {
		width:100%;
		text-align: center;
	}

	.edu-img {
		width: 50px;
		border-radius: 5px;
	}

	.edu-separator {
		border-bottom: 1px solid #bebebe;
		margin-top: 40px;
	}

	.edu-content {
		color: #1a1a1a;
		font-size: 14px;
		font-family: 'Montserrat';
		width: 100%;
		margin-top: 10px;
	}

	.edu-title {
		color: #1a1a1a !important;
		font-size: 14px !important;
		font-family: 'Montserrat' !important;
		text-align: center !important;
	}

	.edu-text {
		color: #0c0c0c;
		font-size: 12px;
		font-family: 'Roboto Slab';
		margin-top: 10px;
	}

	.edu-sub-title {
		color: #777676 !important;
		font-size: 10px !important;
		font-family: 'Montserrat' !important;
		text-align: center !important;
		margin-top: 5px !important;
	}

	.edu-sub-title > a {
		text-align: center !important;
	}

	.main-contents-container {
		width: 100%;
		padding-bottom: 20px;
		margin: auto;
	}

	.mobile-only {
		display: block;
	}

	.nav-default {
		font-size: 15px;
		margin-top: 20px;
		color: #bebebe;
		cursor: pointer;
		text-align: center !important;
	}

	.nav-default:hover {
		cursor: pointer;
		color: #ffffff;
		text-align: center !important;
	}

	.nav-selected {
		font-size: 20px;
		margin-top: 20px;
		color: #ffffff;
		cursor: pointer;
		text-align: center !important;
	}

	.sidebar-container {
		padding: 10px;
		left: 0;
		top: 0;
		right: 0;
		background: #0c0c0c;
		position: fixed;
	}

	.sidebar-img {
		height: 50px;
		width: 50px;
		border-radius: 50%;
		float: left;
	}

	.sidebar-name {
		font-size: 20px;
		width: 140px;
		float: left;
	}

	.sidebar-sub-name {
		color: #777676;
		width: 140px;
		font-size: 12px;
	}

	.sidebar-social-container {
		width: 100%;
		margin-top: 30px;
		font-size: 16px;
	}

	.sidebar-nav-container {
		width: 100%;
		display: none;
		text-align: center;
	}

	.social-left {
		margin-right: 12px;
	}

	.social-mid {
		margin-left: 12px;
		margin-right: 12px;
	}

	.social-right {
		margin-left: 12px;
	}

	.skills-ul {
		list-style: none;
		margin: 0px;
		padding: 0px;
	}

	.skills-ul li {
		display: inline-block;
		float: left;
		width: 170px;
		margin-top: 15px;
	}

	#category_select {
		width: 100%;
		margin-top: 10px;
		font-size:20px;
	}

	.title-container {
		display: inline-block;
		margin-left: 10px;
	}

	.web-only {
		display: none;
	}

	.faport {
		width: 100%;
		display: inline-block;
		text-align: center;
	}
}

